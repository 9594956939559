
































































import {Component, Vue} from "vue-property-decorator";
import {ISms, Sms} from '@/utils/Sms'
import {apiUser} from '@API/ApiUser'

@Component({
  name: "Login",
  components: {},
})

export default class Home extends Vue {
  private sms: ISms = new Sms()
  private form: any = {
    mobile: '',
    pwd: '',
    code: '',
    type: 1
  }

  created() {
    if (localStorage.getItem('is_login')) {
      this.$router.push('/home')
    }
  }

  handleSendSms() {
    this.sms.sendSms(this.form.mobile, 'login')
  }

  handleChangeLoginType(type: number) {
    this.form.type = type
  }

  async login() {
    let form = this.form
    if (form.mobile.length !== 11) {
      this.$toast.fail('请输入正确的手机号码');
      return
    }
    if (form.type === 2 && !form.pwd) {
      this.$toast.fail('请输入密码');
      return
    }
    if (form.type === 1 && !form.code) {
      this.$toast.fail('请输入验证码');
      return
    }

    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
    })

    apiUser.login({
      username: form.mobile,
      code: form.code,
      password: form.pwd,
      type: form.type
    })
        .finally(() => {
          loading.clear()
        })
        .then(res => {
          localStorage.setItem('is_login', '1')
          if (res.data) {
            this.$router.push('/home')
          }
        })
        .catch(e => {
          this.$dialog({
            title: '登录失败',
            message: e.message
          })
        })
  }
}
